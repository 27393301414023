import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import {Link as Link2} from "react-router-dom";
import Background from './images/spikesinfog.jpg';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        //borderColor: transparent;
        borderRadius: '2rem',
        backgroundColor: 'rgba(229, 229, 229, .8)',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    bgimage: {
        top: '0px !important',
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: -1,

        display: 'block',
        backgroundImage: `url(${Background})`,
        opacity: '.75',
        width: '100%',
        height: '100%',

        webkitFilter: 'blur(5px)',
        mozFilter: 'blur(5px)',
        oFilter: 'blur(5px)',
        msFilter: 'blur(5px)',
        filter: 'blur(5px)',
    },
}));


//var URLSearchParams2 = require('url-search-params');

export default function SignIn() {
    const classes = useStyles();
    var state = { email: "", password: "" };
    const saveForm = () => {
        var address = process.env.REACT_APP_AUTH_URL + "login/" + "?username="+state.email + "&password="+state.password;
        //var data = new URLSearchParams2({'username':state.email, 'password': state.password});
        //var form = new FormData();
        //form.append("username",state.email);
        //form.append("password",state.password);
        fetch(address, {
            method: "POST",
            withCredentials: true,
            credentials: 'same-origin',
            //header:{"Content-Type": 'application/json'},
            //body: data,

        }).then((res) => {
            console.log(res);
                if (res.redirected) {
                    window.location = res.url;
                    
                } else alert("Failed to Login");
            })
            .catch((err) => {
                console.log(err);
                console.log('Error fetching authorized user.');
            });
    };
    const updateState = (e) => {
        var eventID = e.target.id;
        var eventValue = e.target.value;
        state[eventID] = eventValue;
    };
    return (
        <div>
            <div className={classes.bgimage} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
        </Typography>
                    <form className={classes.form} noValidate onSubmit={(e) => {
                        e.preventDefault();
                        saveForm();
                    }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address "
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={updateState}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={updateState}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me (WIP)"
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={saveForm}
                        >
                            Login
          </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password? We don't Care Thats to Much Work.
              </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    );
}