import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import theme from "./theme.js";
import "./drawer.css";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { RecipeEditor } from "./RecipeEditorV2";
import { RecipeDisplay } from "./RecipeDisplay";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
//Input
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TuneIcon from '@material-ui/icons/Tune';

//End Input
//Nested Lists
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const drawerWidth = 600;

export default class RecipeList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [{ name: "Loading", id: -1, mealType: "Loading" }],
      user: {isEditor:false},
    };
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    document.title = "Haum Cookbook";
    
    fetch(process.env.REACT_APP_API_URL + "auth/user", {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
            credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;
          return;
        } 
        return response.json();
          
    }).then(data => {
    
        this.setState({ user: data.user });
      });
    
    fetch(process.env.REACT_APP_API_URL + "recipelist", {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
            credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;
          return
        } else {return response.json();}})
      .then(data => {
        this.setState({ list: data.response });
      });
      


  }

  addItem(e) {
    // Prevent button click from submitting form
    e.preventDefault();

    // Create variables for our list, the item to add, and our form
    let list = this.state.list;
    const newItem = document.getElementById("addInput");
    const form = document.getElementById("addItemForm");

    // If our input has a value
    if (newItem.value !== "") {
      // Add the new item to the end of our list array
      list.push(newItem.value);
      // Then we use that to set the state for list
      this.setState({
        list: list
      });
      // Finally, we need to reset the form
      newItem.classList.remove("is-danger");
      form.reset();
    } else {
      // If the input doesn't have a value, make the border red since it's required
      newItem.classList.add("is-danger");
    }
  }

  removeItem(item) {
    // Put our list into an array
    const list = this.state.list.slice();
    // Check to see if item passed in matches item in array
    list.some((el, i) => {
      if (el === item) {
        // If item matches, remove it from array
        list.splice(i, 1);
        return true;
      }
    });
    // Set state to list
    this.setState({
      list: list
    });
  }
  render() {
    const drawerWidth = 300;
    const classes = {
      root: {
        display: "flex",
        boxSizing: "borderbox"
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: theme.zIndex.drawer,
        paddingTop: "70px"
      },
      drawerPaper: {
        width: "inherit"
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3)
      },
      //toolbar: theme.mixins.toolbar,
      toolbar: {
        flexShrink: 0,
        height: "70px"
      },
      app: {
        width: `calc(100% - (${drawerWidth}+10)px)`,
        marginLeft: drawerWidth+10,
        //display: 'flex',
        alignItems: "center"
        //justifyContent: 'center',
      },
      drawerListItem: {
        maxWidth: drawerWidth
      }
    };
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <div style={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" style={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" noWrap>
                  Haum CookBook
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              style={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <div style={classes.toolbar} />
              <ListStuff
                items={this.state.list}
                delete={this.removeItem}
                user={this.state.user}
                switchItem={this}
                style={classes.content}
              />
            </Drawer>
          </div>
          <main>
            {/**<div style={classes.toolbar} />**/}
            <div style={classes.app}>
              <Switch>
                <Route path="/">
                  <Route path="/editor/:recipeID" component={RecipeEditor}>
                  </Route>
                  <Route
                    path="/recipe/:id"
                    render={(routeProps) => (<RecipeDisplay {...routeProps} user={this.state.user}/>)}
                  ></Route>
                </Route>
              </Switch>
            </div>
          </main>
        </Router>
      </ThemeProvider>
    );
  }
}

class ListStuff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ name: "Loading", id: -1, mealType: "Loading" }],
      filtered: [{ name: "Loading", id: -1, mealType: "Loading" }],
      lastMealType: "",
      anchorEl: null,
      mealTypes: {},
      isOpen: {},
      user: {isEditor:false},
    };
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleMenuSelection = this.handleMenuSelection.bind(this);
    //this.handleChange = this.handleChange.bind(this);
    this.scheduleChange = this.scheduleChange.bind(this);
    this.id = null;
    this.searchEvent = null;
    fetch(process.env.REACT_APP_API_URL + "mealTypes", {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;
          return;
        }  
        return response.json(); 
      })
      .then(data => {
        
        var hold = {};
        data.response.forEach(element => { hold[element.mealType] = false; });
        this.setState({ mealTypes: hold });
        
      });

  }

  componentDidMount() {
    this.setState({
      filtered: this.props.items,
      items: this.props.items,
      isOpen: {},
      user: this.props.user,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filtered: nextProps.items,
      items: nextProps.items,
      isOpen: {},
      user: nextProps.user,
    });
  }

  scheduleChange(e) {
    if (this.id != null) {
      clearTimeout(this.id);
    }

    // If the search bar isn't empty
    if (e.target.value !== "") {
      let newList = [];
      // Assign the original list to currentList
      //currentList = this.state.items;
      /*currentList.filter(item => {
        return this.state.mealTypes[item.mealType];
      });*/
      // Use .filter() to determine which items should be displayed
      // based on the search terms
      const filter = e.target.value.toLowerCase();
      
      //this.state.mealTypes = hold;
      this.id = setTimeout(function (obj) {
        let hold = Object.assign(obj.state.mealTypes);
        for (let key of Object.keys(hold)) {
          hold[key] = true;
        }
        newList = obj.state.items.filter(item => {
          // change current item to lowercase
          const lc = item.name.toLowerCase();
          // change search term to lowercase
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
          return lc.includes(filter);
        });
        obj.setState({
          filtered: newList,
          mealTypes: hold,
        });
      }, 300, this);
    } else {
      this.id = setTimeout(function (obj) {
        var hold = Object.assign(obj.state.mealTypes);
        for (var key of Object.keys(hold)) {
          hold[key] = false;
        }
        obj.setState({
          filtered: obj.state.items,
          mealTypes: hold,
        });
      }, 50, this);
    }

  }

  _handleChange(e) {
    // Variable to hold the filtered list before putting into state
    let newList = [];
    // If the search bar isn't empty
    if (e.target.value !== "") {
      // Assign the original list to currentList
      // Use .filter() to determine which items should be displayed
      // based on the search terms
      let hold = Object.assign(this.state.mealTypes);
      for (let key of Object.keys(hold)) {
        hold[key] = true;
      }
      this.state.mealTypes = hold;
      const filter = e.target.value.toLowerCase();
      newList = this.state.items.filter(item => {
        // change current item to lowercase
        const lc = item.name.toLowerCase();
        // change search term to lowercase
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        return lc.includes(filter);
      });
      this.setState({
        filtered: newList,
        mealTypes: hold,
      });
    } else {
      var hold = Object.assign(this.state.mealTypes);
      for (var key of Object.keys(hold)) {
        hold[key] = false;
      }
      //this.state.mealTypes = hold;
      //Object.keys(this.state.mealTypes).forEach(key => this.state.mealTypes[key] =false);
      // If the search bar is empty, set newList to original task lis
      /*newList.filter(item => {
        return this.state.mealTypes[item.mealType];
      });*/
      this.setState({
        filtered: this.state.items,
        mealTypes: hold,
      });
    }
    this.forceUpdate();
    // Set the filtered state based on what our rules added to newList
  }

  handleMenuClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose() {
    this.setState({ anchorEl: null })
  }

  handleMenuSelection(event) {
    var key = event.target.id;
    var hold = Object.assign(this.state.mealTypes);
    if (key === "all") {
      for (var key of Object.keys(hold)) {
        hold[key] = true;
      }
    } else if (key === "none") {
      for (var key of Object.keys(hold)) {
        hold[key] = false;
      }
    } else {
      hold[key] = !(hold[key]);
    }
    this.state.mealTypes = hold;
    this.forceUpdate();
  }

  render() {

    const drawerWidth = 300;
    const inputclass = {
      root: {
        boxSizing: "borderbox",
        margin: "8px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: drawerWidth,
        whiteSpace: "normal",
        wordWrap: "break-word"
      },
      input: {
        boxSizing: "borderbox",
        margin: "10px",
        marginLeft: theme.spacing(1),
        flex: 1
      },
      iconButton: {
        padding: 10
      },
      listdivider: {
        margintop: "10px",
        marginbottom: "10px"
      },
      toolbar: theme.mixins.toolbar,
      addbutton: {
        display: "flex",
        margin: "8px",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
        width: "250px"
      },
      linkstyleing: {
        color: "inherit",
        textDecoration: "none"
      },
      listItem: {
        boxSizing: "borderbox",
        //wordWrap: "break-word",
        flexShrink: 1,
        flexWrap: "wrap",
        //textOverflow: 'ellipsis',
      },
      listItemText: {},
    };
    var lastMealType = "";
    return (
      <div>
        <ThemeProvider theme={theme}>
          {/*<div style={inputclass.toolbar} />*/}
          <Paper component="form" style={inputclass.root}>
            <InputBase
              style={inputclass.input}
              placeholder="Search All Recipes"
              inputProps={{ "aria-label": "Search All Recipes" }}
              onChange={this.scheduleChange}
            />
            <IconButton
              style={inputclass.iconButton}
              aria-controls="mealtype-filter-menu" aria-haspopup="true" onClick={this.handleMenuClick}
            >
              <TuneIcon />
            </IconButton>
            <Menu
              id="mealtype-filter-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleMenuClose}
            >
              <MenuItem id="all" onClick={this.handleMenuSelection}> <AddCircleOutlineIcon /> {" Show All"}</MenuItem>
              <MenuItem id="none" onClick={this.handleMenuSelection}> <RemoveCircleOutlineIcon /> {" Show None"}</MenuItem>
              {Object.keys(this.state.mealTypes).map((keyName) => (
                <MenuItem id={keyName} onClick={this.handleMenuSelection}>{(this.state.mealTypes[keyName]) ? (<CheckBoxIcon />) : (<CheckBoxOutlineBlankIcon />)}{" " + keyName}</MenuItem>
              ))}
            </Menu>
          </Paper>
        </ThemeProvider>
        <Divider style={inputclass.listdivider} />
        {(this.state.user.isEditor) ? (<Link to="/editor/0" style={inputclass.linkstyleing}>
          <Button
            size="medium"
            variant="contained"
            color="secondary"
            style={inputclass.addbutton}
          >
            <AddIcon />
          </Button>
        </Link>) : null}
        <List>
          {this.state.filtered.map((text, index) => (
            <div>
              {(lastMealType !== text.mealType) ? (
                <div >
                  <ListItem onClick={this.handleMenuSelection} button id={text.mealType} divider={true} style={{
                    wordWrap: "break-word",
                    boxSizing: "borderbox",
                    
                    flex: 1,
                    flexWrap: "wrap"
                  }}>

                    <ListItemIcon style={{ pointerEvents: "none" }}>
                      <FastfoodIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={lastMealType = text.mealType}
                      style={{
                        wordWrap: "break-word",
                        boxSizing: "borderbox",
                        
                        fontWeight: 'bold',
                        flex: 1,
                        flexWrap: "wrap",
                        pointerEvents: "none",
                      }}
                    />
                    {this.state.mealTypes[text.mealType] ? <ExpandLess style={{ pointerEvents: "none" }} /> : <ExpandMore style={{ pointerEvents: "none" }} />}
                  </ListItem></div>)
                : null}
              {(this.state.mealTypes[text.mealType]) ? (
                <Link to={"/recipe/" + text.id} style={inputclass.linkstyleing}>
                  <ListItem button key={text.name} className={"mealType-" + text.mealTypeID} style={{
                    padding: '10px',
                    
                    //wordWrap: "break-word",
                    boxSizing: "borderbox",
                    //maxWidth: "calc(${drawerWidth}-50)px",
                    width: drawerWidth,
                    flex: 1,
                    flexWrap: "wrap",
                    //flexDirection: 'column',
                    //display: 'flex',
                    //pointerEvents: "none",
                    //textOverflow: 'ellipsis',
                  }}>
                    <ListItemText
                      primary={text.name}
                      //secondary={text.mealType}
                      style={{
                        //Width: drawerWidth-70,
                        //flexDirection: 'row',
                        wordWrap: "break-word",
                        boxSizing: "borderbox",
                        fontWeight: 'bold',
                        flex: 1,
                        flexWrap: "wrap",
                        pointerEvents: "none",
                  }}
                />
                  </ListItem>
                </Link>
              ) : null}
            </div>
          ))}
        </List>
      </div>
    );
  }
}
