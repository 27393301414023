import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import RecipeList from "./RecipeList";
import theme from "./theme.js";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";


/*
const auth = {
  isAuthenticated: false,
  pinged: false,
  authenticate(cb) {
    // req.user on backend will contain user info if
    // this person has credentials that are valid
    fetch(process.env.REACT_APP_AUTH_URL + 'user/', {
      method: "GET",
      withCredentials: true,
      credentials: 'include',
    })
      .then((res) => {
        this.pinged = true;
        this.isAuthenticated = true;
        if (typeof cb === 'function') {
          cb(res.json().user);
        }
      })
      .catch((err) => {
        console.log('Error fetching authorized user.');
      });
  },
  checkAuth() {
    if (this.pinged)
      return this.isAuthenticated;
    else
      this.authenticate((e) => {return this.isAuthenticated});
    //return this.isAuthenticated;
  },
  signout(cb) {
    fetch(process.env.REACT_APP_AUTH_URL + 'logout/', {
      method: 'GET',
      credentials: 'same-origin',
      withCredentials: true,
    })
      .then((res) => {
        this.pinged = false;
        this.isAuthenticated = false;
        if (typeof cb === 'function') {
          // user was logged out
          cb(true);
        }
      })
      .catch((err) => {
        console.log('Error logging out user.');
        if (typeof cb === 'function') {
          // user was not logged out
          cb(false);
        }
      });
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    auth.checkAuth() ? (
      <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
  )} />
)*/

function App() {
  var classes = {
    root: {
      display: "flex"
    },
  };
  //auth.authenticate((e) => {});
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
            <Route path="/login">
              <SignIn />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
          
          <div className={classes.root}>
            {/*<PrivateRoute path="/*" component={RecipeList} />*/}
          <Route path="/*" >
              <RecipeList />
  </Route>
          </div>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
