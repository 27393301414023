import React from "react";
import Typography from "@material-ui/core/Typography";
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import theme from "./theme.js";
import { ThemeProvider } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export class RecipeDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      recipeID: -1,
      ingredients: [],
      steps: [],
      cookTime: 0,
      mealType: "",
      servingSize: "",
      recipeName: "",
      user:{isEditor:false}
    };
    this.loadData = this.loadData.bind(this);
    this.convertCookTime = this.convertCookTime.bind(this);
  }

  componentDidMount() {
    this.setState({
      id: this.props.match.params.id,
      recipeName: "",
      user: this.props.user
    });
    this.loadData(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.id === nextProps.match.params.id) {
    } else {
      this.setState({
        id: nextProps.match.params.id,
        recipeName: "",
        user: nextProps.user
      });
      this.loadData(nextProps.match.params.id);
    }
  }

  loadData(id) {
    fetch(process.env.REACT_APP_API_URL+"recipeinfo/" + id, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;

        } else { return response.json(); }
      })
      .then(data => { 
        
        this.setState(data.response); this.convertCookTime(); 
        
      });
    fetch(
      process.env.REACT_APP_API_URL+"recipeingredient/" + id,
      {
        method: "get",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        credentials: 'same-origin',
      }
    )
      .then(response => {
        if (response.redirected) {
          window.location = response.url;

        } else { return response.json(); }
      })
      .then(data => {
        
        this.setState({ ingredients: data.response });
        
      });
    fetch(process.env.REACT_APP_API_URL + "recipestep/" + id, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;

        } else { return response.json(); }
      })
      .then(data => {
        
        this.setState({ steps: data.response });
        
      });
  }

  convertCookTime() {
    var time = this.state.cookTime;
    var string = ""
    if (time == null || time < 1) {
      this.state.cookTime = null;
      this.setState({ cookTime: null })
      return null;
    }
    if (time / 60 >= 1) {
      if (time % 60 > 0) {
        string += Math.floor(time / 60) + " Hour(s) and";
        time = time % 60;
      } else {
        string += Math.floor(time / 60) + " Hours";
        time = time % 60;
      }
    }
    if (time > 0) {
      string += " " + time + " Minutes";
    }
    this.state.cookTime = string;
    this.setState({ cookTime: string });
  }

  deleteRecipe() {
    fetch(process.env.REACT_APP_API_URL +"deleterecipe/" + this.state.id, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    }).then((data) => {
      if (data.redirected) {
        window.location = data.url;
      } 
    });
  }

  render() {
    const classes = {
      root: {
        //display: "flex",
        boxSizing: "borderbox",
        paddingLeft:"10px",
      },
      text: {
        marginTop: theme.spacing(1)
      },
      drawerPaper: {
        width: "inherit"
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3)
      },
      linkstyleing: {
        color: "inherit",
        textDecoration: "none"
      },
      button: {
        display: "flex",
        margin: "8px",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
        width: "250px"
      },
      loading: {
        display: "block",
        margin: "auto",
        alignItems: "center",
      },
    };
    return (
      <ThemeProvider>
        {(this.state.recipeName === "") ? (<div style={classes.loading}><CircularProgress /></div>) : (
          <div style={classes.root}>
            <br />
            <Typography variant="h5" component="h5">
              {this.state.recipeName}
            </Typography>
            <br />
            {(this.state.creator !== null && this.state.creator !== "") ? (<Typography style={classes.text}><b>Recipe Creator:</b> {this.state.creator}</Typography>) : (<div />)}
            {(this.state.cookTime !== null) ? (<Typography style={classes.text}><b>Time:</b> {this.state.cookTime}</Typography>) : (<div />)}
            {(this.state.servingSize !== null && this.state.servingSize !== "") ? (<Typography style={classes.text}><b>Serving Size:</b> {this.state.servingSize}</Typography>) : (<div />)}
            <Typography style={classes.text}><b>Meal Type:</b> {this.state.mealType}</Typography>
            <br />
            <Typography variant="h6" component="h6">
              {"Ingredients"}
            </Typography>
            {this.state.ingredients.map(value => (
              <Typography style={classes.text}>
                {value.amount} {value.ingredient}
              </Typography>
            ))}
            <br />
            <Typography variant="h6" component="h6">
              {"Instructions"}
            </Typography>
            {this.state.steps.map(value => (
              <Typography style={classes.text}>
                <b>{Number(value.stepNumber) + 1}.</b> {value.step}
              </Typography>
            ))}
            <div/>
            {(this.state.user.isEditor) ? (
            <div><Link to={"/editor/" + this.state.recipeID} style={classes.linkstyleing}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={classes.button}>
                <EditIcon />
              </Button>
            </Link>
            <DeleteModal id={this.state.id} /></div>) : null}
            <div/>
          </div>)}
      </ThemeProvider>
    );
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: 'absolute',
    width: 400,
    border: '2px solid #000',
  };
}

export default function DeleteModal(id) {
  const classes = {
    paper: {
      position: 'absolute',
      width: 400,
      border: '2px solid #000',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      margin: theme.spacing(1),
    },
    button: {
      display: "flex",
      margin: "8px",
      padding: "2px 4px",
      alignItems: "center",
      height: "50px",
      width: "250px"
    },
    button2: {
      display: "flex",
      margin: "8px",
      padding: "2px 4px",
      alignItems: "center",
      height: "50px",
      width: "150px",
      float: "left",
    },
    buttondel: {
      display: "flex",
      margin: "8px",
      padding: "2px 4px",
      alignItems: "center",
      height: "50px",
      width: "150px",
      backgroundColor: 'rgba(221, 44, 0, 0.87)',
      float: "right",
    },
    paragraph: {
      display: "flex",
      boxSizing: "borderbox",
      margin: "8px",
      padding: "2px 4px",
      alignItems: "center",
      width: "100%",
    },
  };
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleteRecipe = () => {
    fetch(process.env.REACT_APP_API_URL + "deleterecipe/" + id.id, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    }).then((data) => {
      if (data.redirected) {
        window.location = data.url;

      } 
    });
    handleClose();
  };

  return (
    <ThemeProvider>
      <div>
        <Button variant="contained" onClick={handleOpen} style={classes.button}>
          <DeleteForeverIcon />
        </Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <Paper style={classes.paper, modalStyle} >
            <Typography variant="h6" component="h6" style={classes.paragraph}>
              Deletion of a Recipe Cannot Be Reversed
          </Typography>
            <Button variant="contained" onClick={handleClose} style={classes.button2}>Cancel</Button>
            <Button variant="contained" style={classes.buttondel} onClick={deleteRecipe}><DeleteForeverIcon /></Button>
          </Paper>
        </Modal>
      </div>
    </ThemeProvider>
  );
}
