import React from "react";
import theme from "./theme.js";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";

/*
const RecipeSchema = Yup.object().shape({
  recipeName: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  cookTime: Yup.number().min(1, "Too Small!"),
  prepTime: Yup.string().min(1, "Too Small!"),
  ingredients: Yup.array()
    .min(1, "Not Enough Ingredients")
    .of(
      Yup.object().shape({
        ingredient: Yup.string()
          .max(100, "To Long!")
          .required("Required"),
        amount: Yup.string()
          .max(50, "To Long!")
          .required("Required")
      })
    ),
  instructions: Yup.array()
    .min(1, "Not Enough Instructions")
    .of(
      Yup.object().shape({
        step: Yup.string()
          .max(480, "To Long!")
          .required("Required")
      })
    )
});*/

export class RecipeEditor extends React.Component {
  constructor(props) {
    super(props);
    var id = parseInt(this.props.match.params.recipeID);
    var doLoad = false;
    this.id = {};
    if (id !== 0) {
      doLoad = true;
    }
    this.handleChange = this.handleChange.bind(this);
    this.scheduleChange = this.scheduleChange.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.state = {
      recipeID: id,
      recipeData: doLoad,
      ingredientsData: doLoad,
      instructionsData: doLoad,
      recipeName: "",
      creator: "",
      mealTypeID: 1,
      cookTime: 0,
      servingSize: "",
      ingredients: [{ ingredient: "", amount: "" }],
      instructions: [{ stepNumber: 1, step: "" }]
    };
    this.loadData = this.loadData.bind(this);
    if (doLoad) {
      this.loadData();
    }
  }

  componentDidMount() {
    var id = parseInt(this.props.match.params.recipeID);
    if (this.state.recipeID !== id) {

      var doLoad = false;
      if (id !== 0) {
        doLoad = true;
      }
      this.setState({
        recipeID: id,
        recipeData: doLoad,
        ingredientsData: doLoad,
        instructionsData: doLoad,
      });
      if (doLoad) {
        this.loadData();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    var id = parseInt(nextProps.recipeID);
    if (this.state.recipeID !== id) {

      var doLoad = false;
      if (id !== 0) {
        doLoad = true;
      }
      this.setState({
        recipeID: id,
        recipeData: doLoad,
        ingredientsData: doLoad,
        instructionsData: doLoad,
      });
      if (doLoad) {
        this.loadData();
      }
    }
  }

  loadData() {
    console.log("Loading data");
    if (this.state.recipeID === 0) { return null };
    fetch(process.env.REACT_APP_API_URL + "recipeinfo/" + this.state.recipeID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    }).then(response => {
      if (response.redirected) {
        window.location = response.url;

      } else { return response.json(); }
    })
      .then(data => {
        this.setState(data.response);
        this.setState({ recipeData: false, mealTypeID: parseInt(this.state.mealTypeID), });
      });
    fetch(process.env.REACT_APP_API_URL + "recipeingredient/" + this.state.recipeID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;

        } else { return response.json(); }
      })
      .then(data => {

        this.setState({ ingredients: data.response, ingredientsData: false });
        console.log(this.state);

      });
    fetch(process.env.REACT_APP_API_URL + "recipestep/" + this.state.recipeID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(response => {
        if (response.redirected) {
          window.location = response.url;

        } else { return response.json(); }
      })
      .then(data => {
        
          this.setState({ instructions: data.response, instructionsData: false });

      });
  }
  scheduleChange(e) {
    //this.handleChange(e);
    if (this.id[e.target.id] != null) {
      clearTimeout(this.id);
      this.id[e.target.id] = null;
    }
    this.id[e.target.id] = setTimeout(function (eventID, eventValue, state) {
      state.handleChange(eventID, eventValue);
      //state.id[event.target.id] = null;
    }, 300, e.target.id, e.target.value, this);
  }

  handleChange(eventID, eventValue) {
    if ((eventID).includes("ingredients")) {
      var index = eventID.replace("ingredients[", "").split("].");
      var point = index[1];
      index = index[0];
      console.log(index);
      this.state.ingredients[index][point] = eventValue;
      this.setState();
    } else if ((eventID).includes("instructions")) {
      var index = eventID.replace("instructions[", "").split("]")[0];
      console.log(index);
      this.state.instructions[index].step = eventValue;
      this.setState();
    } else {
      this.setState({ [eventID]: eventValue });
    }
    //this.state[eventID] = eventValue;
    //this.setState();
  }

  componentWillUnmount() {
    if (!this.state.saved) {
      if (window.confirm('You have unsaved Changes. Press Ok to Save, or Cancel to Continue without Saving?')) { // publish and go away from a specific page 
        this.saveForm();
      } else {
        // do nothing and go away from a specific page
      }
    }
  }

  saveForm() {
    var json = JSON.stringify(this.state, null, 2);
    var address = process.env.REACT_APP_API_URL + "newrecipe";
    if (this.state.recipeID !== 0)
      address = process.env.REACT_APP_API_URL + "editrecipe/" + this.state.recipeID;
    var res = fetch(
      address,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: json,
        withCredentials: true,
        credentials: 'same-origin',
      }
    )
      .then(response => {
        if (response.redirected) {
          window.location = response.url;

        } else { return response.json(); }
      })
      .then(data => {

        if (data.response === true) {
          this.state.saved = true;
          this.props.history.push("/app/recipe/" + data.recipeID)
        } else alert("Failed to Submit, An Error occurred and has been logged");

      });
  }

  render() {

    const meals = [
      { string: "Appetizers & Snacks", id: 1 },
      { string: "Bread", id: 3 },
      { string: "Crockpot", id: 4 },
      { string: "Desserts", id: 5 },
      { string: "Eggs & Cheese", id: 6 },
      { string: "Fish", id: 7 },
      { string: "Meat", id: 8 },
      { string: "Pasta", id: 9 },
      { string: "Pies & Tarts", id: 10 },
      { string: "Poultry", id: 11 },
      { string: "Salads", id: 12 },
      { string: " Sauces & Marinades", id: 13 },
      { string: "Soups", id: 14 },
      { string: "Vegetables & Fruits", id: 15 },
      { id: 16, string: "Rice and Grains" },
      { id: 17, string: "Cookies" },
      { id: 18, string: "Cakes" },
      { id: 19, string: "Sandwiches" },
      { id: 20, string: "Jams" },
      { id: 21, string: "Main Dishes(Casseroles)" },
      { id: 22, string: "Beverage" },
      { id: 23, string: "Candy" },
    ];
    const Style = {
      removeButton: {
        display: "flex",
        flexdirection: "row",
        height: "100%",
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginTop: "auto",
        marginBottom: "auto",
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      },
      removebuttonSpacing: {
        flexdirection: "row",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
      },
      listInput: {
        width: "85%",
        display: "flex"
      },
      listItem: {
        boxsizing: "borderbox",
        width: "50%",
        margin: theme.spacing(1)

        //flex: 1
      },
      instructItem: {
        boxsizing: "borderbox",
        width: "80%",
        margin: theme.spacing(1),
        flexgrow: 1,
        flex: 1
      }
    };

    return (
      <div>
        {(this.state.recipeID !== 0 && (this.state.recipeData || this.state.ingredientsData || this.state.instructionsData)) ? (<CircularProgress />) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.saveForm();
            }}>
            <TextField
              id="recipeName"
              name="recipeName"
              label="Recipe Name"
              style={{ margin: theme.spacing(1) }}
              margin="normal"
              size="normal"
              defaultValue={this.state.recipeName}
              variant="outlined"
              type="text"
              helperText="Recipe Name"
              onChange={this.scheduleChange}
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              id="creator"
              name="creator"
              label="Recipe Creator"
              style={{ margin: theme.spacing(1) }}
              margin="normal"
              size="normal"
              defaultValue={this.state.creator}
              variant="outlined"
              type="text"
              helperText="Recipe Creator"
              onChange={this.scheduleChange}
              inputProps={{ maxLength: 50 }}
            />
            <Select
              as="select"
              native
              id="mealTypeID"
              name="Meal Type"
              helperText="Type of Meal"
              label="Type of Meal"
              size="normal"
              margin="normal"
              variant="outlined"
              defaultValue={this.state.mealTypeID}
              style={{ margin: theme.spacing(1) }}
              onChange={this.scheduleChange}
              autoWidth={true}
            //displayEmpty={true}
            >
              {meals.map((meal) => (
                <option value={meal.id}>{meal.string}</option>
              ))}
            </Select>

            <TextField
              id="cookTime"
              name="cookTime"
              style={{ margin: theme.spacing(1) }}
              label="Cook Time"
              helperText="Cooking Time (Minutes)"
              type="number"
              size="normal"
              margin="normal"
              variant="outlined"
              defaultValue={this.state.cookTime}
              onChange={this.scheduleChange}
            />

            <TextField
              id="servingSize"
              name="servingSize"
              label="Serving Size"
              variant="outlined"
              type="text"
              helperText="Serving Size"
              style={{ margin: theme.spacing(1) }}
              margin="normal"
              size="normal"
              defaultValue={this.state.servingSize}
              inputProps={{ maxLength: 50 }}
              onChange={this.scheduleChange}
            />
            <div>
              {this.state.ingredients.map((ingredient, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div style={Style.listInput}>
                    <TextField
                      style={Style.listItem}
                      name={"ingredients[" + index + "].ingredient"}
                      id={"ingredients[" + index + "].ingredient"}
                      variant="outlined"
                      margin="normal"
                      label="Ingredient"
                      helperText="Ingredient"
                      type="text"
                      size="normal"
                      defaultValue={ingredient.ingredient}
                      //value={this.state.ingredients[index].ingredient}
                      onChange={this.scheduleChange}
                      inputProps={{ maxLength: 100 }}
                    />
                    <TextField
                      type="text"
                      style={Style.listItem}
                      name={"ingredients[" + index + "].amount"}
                      id={"ingredients[" + index + "].amount"}
                      variant="outlined"
                      margin="normal"
                      size="normal"
                      label="Ingredient Amount"
                      helperText="Ingredient Amount"
                      defaultValue={this.state.ingredients[index].amount}
                      //value={this.state.ingredients[index].amount}
                      onChange={this.scheduleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </div>
                  <Button
                    size="large"
                    variant="contained"
                    margin="normal"
                    style={Style.removeButton}
                    onClick={() => { this.state.ingredients.splice(index, 1); this.forceUpdate(); }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              ))}

              <Button
                size="medium"
                variant="contained"
                color="secondary"
                style={{
                  width: "85%",
                  margin: theme.spacing(1),
                  justifyContent: "center",
                  alignItems: "center",

                  padding: theme.spacing(1),
                  flex: 1,
                  alignItem: "center"
                }}
                onClick={() => {
                  this.state.ingredients.push({ ingredient: "", amount: "" });
                  this.forceUpdate();
                }
                }
              >
                <AddIcon />
              </Button>
            </div>

            <div>
              {this.state.instructions.map((instruction, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div style={Style.listInput}>
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {index + 1}.
                          </h3>
                    <TextField
                      style={Style.instructItem}
                      name={"instructions[" + index + "].step"}
                      id={"instructions[" + index + "].step"}
                      variant="outlined"
                      multiline
                      size="normal"
                      margin="normal"
                      label="Instructions"
                      helperText={"Step " + (index + 1)}
                      rows="3"
                      defaultValue={this.state.instructions[index].step}
                      onChange={this.scheduleChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ maxLength: 480 }}
                    />
                  </div>

                  <Button
                    size="medium"
                    style={Style.removeButton}
                    margin="normal"
                    variant="contained"
                    onClick={() => { this.state.instructions.splice(index, 1); this.forceUpdate(); }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              ))}
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                margin="normal"
                style={{
                  width: "85%",
                  margin: theme.spacing(1),
                  justifyContent: "center",
                  alignItems: "center",

                  padding: theme.spacing(1)
                }}
                onClick={() => {
                  this.state.instructions.push({
                    stepNumber: this.state.instructions.size,
                    step: "",
                  })
                  this.forceUpdate();
                }
                }
              >
                <AddIcon />
              </Button>
            </div>

            <Button
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
            >
              Save Recipe
              </Button>
          </form>
        )}
      </div>

    );
  }
}