import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({

  palette: {
    primary: {
      dark: '#0288D1',
      main: "#03A9F4",
      light: '#B3E5FC',
      //contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00BCD4',
      //contrastText: '#FFFFFF',
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    },
    divider: '#BDBDBD',
    /*primary: {
      main: '#1565c0',
    },
    secondary: {
      main: '#99dfff',
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    }*/
  }
});

export default theme;
