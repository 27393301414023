import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Background from './images/spikesinfog.jpg';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),

        borderColor: 'transparent',
        borderRadius: '2rem',
        backgroundColor: 'rgba(229, 229, 229, .8)',

    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        padding: theme.spacing(1),
        background: '',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    bgimage: {
        top: '0px !important',
        margin: 0,
        padding: 0,
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: -1,


        backgroundImage: `url(${Background}) `,
        opacity: '.75',
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        webkitFilter: 'blur(5px)',
        mozFilter: 'blur(5px)',
        oFilter: 'blur(5px)',
        msFilter: 'blur(5px)',
        filter: 'blur(5px)',

    },
}));

export default function SignUp() {
    const classes = useStyles();
    var state = { email: "", password: "", firstName: "", lastName: "" };
    const saveForm = () => {
        var json = JSON.stringify(state, null, 2);
        var address = process.env.REACT_APP_AUTH_URL + "signup/";

        var res = fetch(
            address,
            {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: json,
            }
        )
            .then(response => response.json())
            .then(data => {
                //console.log(data);
                if (data.sucess) {
                    window.location = process.env.REACT_APP_LOGIN_INTERFACE + "login";
                    //this.props.history.push("/auth/login");
                } else alert(data.message);
            });
    };
    const updateState = (e) => {
        var eventID = e.target.id;
        var eventValue = e.target.value;
        state[eventID] = eventValue;
    };
    return (
        <div>
            <div className={classes.bgimage} />
            <Container component="main" maxWidth="xs">

                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up Not Currently Enabled
        </Typography>
                    <form className={classes.form} onSubmit={(e) => {
                        e.preventDefault();
                        saveForm();
                    }} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    onChange={updateState}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    onChange={updateState}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={updateState}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={updateState}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive update emails when that is eventually setup."
                            />
</Grid>*/}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign Up
          </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
              </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    );
}